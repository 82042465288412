
import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  OutlinedInput
} from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  textField: {
    display: 'flex',
    alignItems: 'baseline',
    width: '100%',
    borderRadius: 0,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0, 1.5, 0, 0)
  },
  input: {
    color: theme.palette.background.primary,
    fontSize: 18,
    fontFamily: 'roboto, sans-serif',
    lineHeight: 'normal',
    padding: theme.spacing(3, 2.5),
    '&:focus': {
      backgroundColor: 'unset'
    },
    '&::placeholder': {
      lineHeight: 'normal',
      opacity: 1,
      color: theme.palette.background.primary,
    },
    '&:-ms-input-placeholder': {
      lineHeight: 'normal',
      opacity: 1,
      color: theme.palette.background.primary,
    },
    '&::-ms-input-placeholder': {
      lineHeight: 'normal',
      opacity: 1,
      color: theme.palette.background.primary,
    }
  },
  notchedOutline: {
    border: 'none'
  },
  errorInput: {
    border: `1px solid ${theme.palette.danger.main}`
  },
  error: {
    color: theme.custom.palette.red,
    padding: theme.spacing(1, 0)
  },
  required: {
    fontSize: 16,
    fontStyle: 'italic',
    color: theme.custom.palette.darkGrey
  }
}));

const FansTextField = React.forwardRef(({
  error,
  type = 'text',
  className,
  required,
  value,
  ...rest
}, ref) => {

  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <OutlinedInput
        inputRef={ref}
        type={type}
        value={value}
        variant='outlined'
        error={!!error}
        endAdornment={
          required && !value &&
          <Typography
            variant='subtitle2'
            className={classes.required}
          >
            Required
          </Typography>
        }
        className={clsx(
          'form-control form-control-lg',
          classes.textField
        )}
        classes={{
          input: classes.input,
          inputMultiline: classes.input,
          error: classes.errorInput,
          notchedOutline: classes.notchedOutline
        }}
        {...rest}
      />
      {
        !!error &&
        <Typography
          variant='subtitle2'
          className={classes.error}
        >
          {error}
        </Typography>
      }
    </div>
  );
});

export default memo(FansTextField);