
import * as yup from 'yup'

const NAME_VALID = yup.string()
  .required('Please enter your name.');

const EMAIL_VALID = yup.string()
  .email('Please enter a valid email address.')
  .required('Please enter your email address.');

const PHONE_REG_EXP = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
const PHONE_VALID = yup.string()
  .matches(PHONE_REG_EXP, 'Please enter a valid phone number.')
  .required('Please enter your email address.');

const QUESTION_VALID = yup.string()
  .required('Please input question.');

export {
  NAME_VALID,
  EMAIL_VALID,
  QUESTION_VALID,
  PHONE_VALID
};