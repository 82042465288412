
import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: 20,
    borderRadius: 0,
    border: `1px solid ${theme.palette.text.primary}`,
    padding: theme.spacing(3, 6),
    minWidth: 200,
    color: theme.palette.text.primary
  }
}));

const OutlinedButton = React.forwardRef(({
  className,
  classes: propClasses = {},
  type,
  color = 'primary',
  href,
  loading,
  disabled,
  children,
  ...rest
}, ref) => {
  const classes = useStyles();

  return (
    <Button
      ref={ref}
      href={href}
      component={href ? 'a' : 'button'}
      className={clsx(className, classes.root)}
      classes={{ ...propClasses, disabled: classes.disabled }}
      color={color}
      variant='outlined'
      type={type}
      disabled={loading || disabled}
      {...rest}>
      {children}
    </Button>
  );
});

export default memo(OutlinedButton);
