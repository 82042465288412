
import axios from '@services/axios'

const connect = async params => {
  var data = {
    service_id: 'service_2bpa8hf',
    template_id: 'template_p0ng9nh',
    user_id: '1wJY68FPtcWSPFWua',
    template_params: {
        'subject': 'To Experiwear support',
        'fullName': params.fullName,
        'question': params.question,
        'email': params.email
    }
  };
  
  return await axios.post('/api/v1.0/email/send', JSON.stringify(data));
};

export {
  connect
};
