
const getConnectErrorMessage = code => {
  switch (code) {
    case 1:
      return 'Full Name is empty.'
    case 2:
      return 'Full Name is required.'
    case 3:
      return 'Email is empty.'
    case 4:
      return 'Email is required.'
    case 5:
      return 'Question is empty.'
    case 6:
      return 'Question is required.'
    default:
      return 'Connect Error'
  }
};

export default getConnectErrorMessage;